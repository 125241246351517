<template>
  <div class="pointsMall_container" :style="`background: ${info.bj_color}`">
    <!-- <div v-if="info.adv_img" class="img" @click="handleJump(info.adv_url)">
      <img :src="info.adv_img" alt="" />
    </div> -->
    <div v-if="info.length > 0" class="img" @click="handleJump(info[0].jump)">
      <img :src="info[0].image.url" alt="" />
    </div>
    <div class="content">
      <div class="title">当前积分商城商品</div>
      <div class="goods_wrap">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="goods_img">
            <img :src="item.image.url" alt="" />
          </div>
          <div class="flex_d_c_c">
            <p class="c3 f16">{{ item.title }}</p>
            <p class="jifen">兑换积分：{{ item.points }}分</p>
            <p class="c9">剩余数量：{{ item.number }}</p>
            <div class="btn flex_c_c c_p" :class="[item.can_exchange == 101 ? 'green' : 'grey']" @click="handleExchange(item)">
              {{ item.btn_str }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="积分兑换" :visible.sync="dialogVisible" width="50%">
      <!-- label-width="80px" -->
      <el-form :model="form" label-width="100px">
        <el-form-item label="所需积分：">
          <span>{{ points }}</span>
        </el-form-item>
        <el-form-item label="收货人：">
          <el-input v-model="form.shr" placeholder="收货人" class="w300" />
        </el-form-item>
        <el-form-item label="收货电话：">
          <el-input v-model="form.shdh" placeholder="收货电话" class="w300" />
        </el-form-item>
        <el-form-item label="收货地址：">
          <div v-if="addressList.length">
            <el-select v-model="form.shdz" placeholder="请选择" @change="getDeliverymethod" style="width: 300px">
              <el-option v-for="item in addressList" :key="item.id" :label="item.address_str" :value="item.id"> </el-option>
            </el-select>
            <el-button plain @click="$router.push('/index/subRouter/address')" class="add-btn"> 去添加地址 </el-button>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>

    <div class="swiper" style="width: 1140px; margin: 0 auto">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, i) in 10" :key="i">
          <img style="width: 100px; height: 100px" :src="item.img" alt="" />
        </div>
        <!-- <div class="swiper-slide">
          <img style="width: 100px; height: 100px" src="https://static.mingyuanyyw.com/uploadfiles/20191218/1576653102.png?x-oss-process=image/resize,m_pad,h_98,w_98" alt="" />
        </div>
        <div class="swiper-slide">slider3</div>
        <div class="swiper-slide">slider4</div>
        <div class="swiper-slide">slider5</div>
        <div class="swiper-slide">slider6</div>
        <div class="swiper-slide">slider7</div>
        <div class="swiper-slide">slider8</div>
        <div class="swiper-slide">slider9</div>
        <div class="swiper-slide">slider10</div>
        <div class="swiper-slide">slider11</div>
        <div class="swiper-slide">slider12</div>
        <div class="swiper-slide">slider13</div>
        <div class="swiper-slide">slider14</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { handleJump } from "@/utils";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  data() {
    return {
      handleJump,
      list: [],
      dialogVisible: false,
      form: {
        prize_id: "", //积分商品id
        shr: "",
        shdh: "",
        shdz: "",
      },
      info: {},
      points: 0,
      addressList: [],
    };
  },
  mounted() {
    new Swiper(".swiper", {
      loop: true,
      slidesPerView: 10,
      spaceBetween: 10,
      autoplay: true,
      // loopedSlides: 5,
    });
  },
  created() {
    this.$api("zone.getPoints").then((res) => {
      this.list = res.data;
    });
    this.$api("cart.getAddress", {}).then((res) => {
      let defaultAddress = res.data.filter((v) => {
        return v.is_default == 101;
      });
      this.addressList = res.data;
      console.log("地址列表----", this.addressList);
      console.log("默认地址----", defaultAddress[0]);
      this.form.shr = defaultAddress[0].name;
      this.form.shdh = defaultAddress[0].phone;
      this.form.shdz = defaultAddress[0].address_str;
    });
    this.$api("zone.getAdv", { mid: localStorage.getItem("mid") }).then((res) => {
      console.log("广告信息-----", res);
      this.info = res.data;
    });
    // getZoneInfo(this.$route.query.value).then((res) => {
    //   this.info = res;
    // });
  },

  methods: {
    handleExchange(item) {
      if (item.can_exchange == 102) return;
      if (item.jfsplx == 102) {
        this.$api("zone.ExchangePoints", { prize_id: item.id }).then(() => {
          this.dialogVisible = false;
          this.$message.success("兑换成功");
        });
      } else {
        this.form.prize_id = item.id;
        this.points = item.points;
        this.dialogVisible = true;
      }
    },
    getDeliverymethod(e) {
      // console.log("选中的值---", e);
      let defaultAddress = this.addressList.filter((v) => {
        return v.id == e;
      });
      this.form.shr = defaultAddress[0].name;
      this.form.shdh = defaultAddress[0].phone;
      this.form.shdz = defaultAddress[0].address_str;
    },
    submit() {
      console.log(this.form);
      if (!this.form.shr || !this.form.shdh || !this.form.shdz) return;
      this.$api("zone.ExchangePoints", this.form).then(() => {
        this.dialogVisible = false;
        this.$message.success("兑换成功");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pointsMall_container {
  background-size: cover;
}
.w300 {
  width: 300px;
}
.img {
  width: 100%;
  height: 375px;
}
.title {
  width: 100%;
  height: 58px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding-left: 20px;
  line-height: 58px;
  box-sizing: border-box;
  margin: 10px 0;
}
.goods_wrap {
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 230px;
    height: 370px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 12px;
    margin-right: 12px;
    padding: 10px;
    box-sizing: border-box;
    &:nth-child(5n) {
      margin-right: 0;
    }
    .goods_img {
      width: 210px;
      height: 200px;
      background: #ffffff;
      border-radius: 4px;
    }
    .jifen {
      font-size: 16px;
      font-weight: bold;
      color: #f93232;
      margin: 14px 0;
    }
    .btn {
      width: 100px;
      height: 32px;
      border-radius: 4px;
      color: #fff;
      margin-top: 15px;
    }
    .green {
      background: @themeColor;
    }
    .grey {
      background-color: #eee;
      color: #333;
    }
  }
}
.add-btn {
  margin-left: 10px;
}
</style>
